import { Map, OrderedMap } from 'immutable';
import * as crypto from 'crypto-js';
import * as FileSaver from 'file-saver';

import {
  CoreGroup,
  CoreHumanResource,
  CoreModel,
  PHRASE,
  TreeActivity,
  TreeNode,
  TreeRelationship
} from '../../core/interface/core.interface';
import { CoreService } from '../../core/service/core.service';
import { CoreUtilities } from '../../core/utilities/core.utilities';
import { Datum } from '../../shared/utilities/datum';
import { Injectable } from '@angular/core';
import { ImportData } from './import.service';
import { NODES_TYPE_HUMANRESOURCE } from '../../shared/api/nodes/nodes.models';

export interface ExportModel extends CoreModel {
  treeNodes: any[];
  treeRelationships: any[];
  treeActivities: TreeActivity[];
}

@Injectable()
export class ExportService {

  readonly boolFields = ['active', 'onreport', 'formBucket', 'obligatory', 'formFieldEditable', 'formFieldSortable', 'formFieldFilterable', 'formFieldFixed', 'formFieldSearchable', 'fullscreen', 'printable', 'hideWidget'];
  readonly intFields = ['id', 'originalId', 'positionX', 'level', 'duplicateOriginalId', 'sorting', 'autoPositionX', 'autoPositionUncrossed', 'height', 'modelType', 'dataId', 'responsible_id', 'businessBenefit',
    'status', 'commercialStatus', 'complexity', 'sizeType', 'uncertainty', 'risk', 'risk_type', 'riskProfileCategory', 'risk_profile_assessment', 'priority', 'percComplete', 'time', 'cost', 'quality', 'executive',
    'program', 'functional', 'resource', 'organizational', 'technical', 'storypoints', 'costtype', 'type', 'workFlowModel', 'group_id', 'dashboardCols', 'dashboardRows', 'dashboardX', 'dashboardY', 'private', 'numberField1',
    'numberField2', 'numberField3', 'numberField4', 'numberField5', 'numberField6', 'numberField7', 'numberField8', 'numberField9', 'numberField10', 'responsible_Id', 'nodeType'];
  readonly floatFields = ['budget', 'budget_actual', 'budget_remaining', 'budgetCalculated', 'benefitBudget', 'benefitActual', 'benefitRemaining', 'costBudget', 'costActual', 'costRemaining', 'investBudget',
    'investActual', 'investRemaining'];
  readonly dateFields = ['target_date', 'target_date_actual', 'start_date_actual', 'start_date', 'creationdate', 'lastupdated'];

  public constructor(protected coreService: CoreService, protected coreUtilities: CoreUtilities) { }

  public export(name: string, models: ExportModel[], humanResourcesMap: OrderedMap<string, CoreHumanResource>, groupsMap: OrderedMap<string, CoreGroup>, output = 'vm') {
    switch (output) {
      case 'vm':
        this.exportVM(name, models, humanResourcesMap, groupsMap);
    }
  }

  protected exportVM(name: string, models: ExportModel[], humanResourcesMap: OrderedMap<string, CoreHumanResource>, groupsMap: OrderedMap<string, CoreGroup>) {
    /* The human resources */
    let humanResources = Map<number, any>();
    /* The groups */
    let groups = Map<number, any>();
    /* The human resource/ group relationships */
    let groupHumanResources = Map<number, any[]>();
    /* Prepare the model data for export */
    models = models.map(model => {
      /* Ids */
      const ids = model.treeNodes.map(treeNode => treeNode.id);
      /* Get relationships */
      let treeActivities = [];
      model.treeRelationships = model.treeRelationships.filter(treeRelationship => ids.indexOf(treeRelationship.parentId) !== -1 && ids.indexOf(treeRelationship.childId) !== -1).map(treeRelationship => this.mapTreeRelationships(treeRelationship));
      model.treeNodes = model.treeNodes.map(treeNode => {
        /* Check if a human resource has been selected */
        if ((humanResourcesMap as OrderedMap<string, CoreHumanResource>).has('' + treeNode.responsibleId)) {
          const humanResource = (humanResourcesMap as OrderedMap<string, any>).get('' + treeNode.responsibleId);
          if (humanResource !== undefined) {
            humanResources = humanResources.set(humanResource.id, this.mapHumanResources(humanResource));
          }
        }
        /* Check if a group has been selected */
        if ((groupsMap as OrderedMap<string, CoreGroup>).has('' + treeNode.groupId)) {
          const group = (groupsMap as OrderedMap<string, any>).get('' + treeNode.groupId);
          if (group !== undefined) {
            groups = groups.set(group.id, this.mapGroup(group));
            /* Check for relationships */
            groupHumanResources = groupHumanResources.set(group.id, treeNode.children.filter(child => child.nodeType === NODES_TYPE_HUMANRESOURCE && child.responsibleId !== null).map(child => child.responsibleId));
          }
        }
        /* Check if there are activities attached */
        treeActivities = treeActivities.concat(treeNode.activities);
        /* Map the vertices */
        return this.mapTreeNodes(treeNode);

      });

      /* Set the activities */
      model.treeActivities = treeActivities;
      /* changing the wrong string values back to boolean values */
      model.treeNodes = model.treeNodes.map(node => ({ ...node, hideOnMobile: this.coreUtilities.parseStringToBoolean(node.hideOnMobile), showOnlyOnMobile: this.coreUtilities.parseStringToBoolean(node.showOnlyOnMobile), partOfTRT: this.coreUtilities.parseStringToBoolean(node.partOfTRT), phantom: this.coreUtilities.parseStringToBoolean(node.phantom) }));
      /* Return model */
      return model;
    });
    /* Name */
    const fileName = this.coreUtilities.toFileNameString(name) + '_' + new Datum().toFileNameString() + '.vm';
    /* Encrypt the data */
    const encrypted = crypto.AES.encrypt(JSON.stringify({ models, humanResources: humanResources.toArray(), groups: groups.toArray(), groupHumanResources: groupHumanResources.filter(d => d.length > 0).toJS() } as ImportData), PHRASE).toString();
    /* Save */
    const fileToSave = new Blob([encrypted], <any>{
      type: 'application/json',
      name: fileName
    });
    FileSaver.saveAs(fileToSave, fileName);
  }

  public mapTreeNodes(treeNode: TreeNode) {
    const exportTreeNode = {};
    const keys = Object.keys(treeNode);
    const count = keys.length;
    for (let i = 0; i < count; i++) {
      let key = keys[i];
      let value = treeNode[key];
      if (value === undefined || value === null || value === '' || value === 0 || ['children', 'parents', 'childIds', 'parentIds', 'activities', 'childrenWithoutGF', 'created_at', 'updatedAt', 'deleted_at',
        'models', 'nodestructures', 'parentsWithoutGF', 'visible', 'unfilteredChildIds', 'unfilteredChildren', 'unfilteredParentIds', 'unfilteredParents', 'subLevel'].indexOf(key) !== -1) {
        continue;
      }
      if (key === 'responsibleId') {
        key = 'responsible_Id';
      }
      if (this.intFields.indexOf(key) !== -1) {
        value = parseInt('' + value);
      } else if (this.floatFields.indexOf(key) !== -1) {
        value = parseFloat('' + value);
      } else if (this.boolFields.indexOf(key) !== -1) {
      } else if (this.dateFields.indexOf(key) !== -1) {
      } else {
        value = '' + value;
      }


      exportTreeNode[key] = value;
    }
    return exportTreeNode;
  }

  protected mapTreeRelationships(treeRelationship: TreeRelationship) {
    const exportTreeRelationship = {};
    const keys = Object.keys(treeRelationship);
    const count = keys.length;
    for (let i = 0; i < count; i++) {
      const key = keys[i];
      let value = treeRelationship[key];
      if (value === undefined || value === null || value === '' || value === 0 || ['originalParentId', 'originalChildId', 'modelId', 'internalType', 'updatedAt', 'version_id', 'rel', 'phantom', 'highlight',
        'source', 'target', 'x1', 'x2', 'y1', 'y2'].indexOf(key) !== -1) {
        continue;
      }
      if (['id', 'parentId', 'childId', 'condition', 'category', 'type'].indexOf(key) !== -1) {
        value = parseInt('' + value);
      }
      if (['weight'].indexOf(key) !== -1) {
        value = parseFloat('' + value);
      }
      exportTreeRelationship[key] = value;
    }
    return exportTreeRelationship;
  }

  protected mapHumanResources(humanResource: CoreHumanResource) {
    const exportHumanResource = {};
    const keys = Object.keys(humanResource);
    const count = keys.length;
    for (let i = 0; i < count; i++) {
      const key = keys[i];
      let value = humanResource[key];
      if (value === undefined || value === null || value === '' || value === 0 || [].indexOf(key) !== -1) {
        continue;
      }
      if (['instanceId'].indexOf(key) !== -1) {
        value = parseInt('' + value);
      }
      exportHumanResource[key] = value;
    }
    return exportHumanResource;
  }

  protected mapGroup(group: CoreGroup) {
    const exportGroup = {};
    const keys = Object.keys(group);
    const count = keys.length;
    for (let i = 0; i < count; i++) {
      const key = keys[i];
      let value = group[key];
      if (value === undefined || value === null || value === '' || value === 0 || [].indexOf(key) !== -1) {
        continue;
      }
      if (['instanceId'].indexOf(key) !== -1) {
        value = parseInt('' + value);
      }
      if (['id'].indexOf(key) !== -1) {
        value = parseInt('' + value);
      }
      exportGroup[key] = value;
    }
    return exportGroup;
  }

}
