import { IPayload } from '../shared';
import { IAction, IFailAction } from '../shared/common.interfaces';
import { CoreField, CoreMultiTransfer, TreeNode } from '../../../core/interface/core.interface';

export class OneTimeTokenAction {
  static key = 'OneTimeToken';

  static LOAD_SUCCESS = `[${OneTimeTokenAction.key}] Load Success`;
  static LOAD_FAIL = `[${OneTimeTokenAction.key}] Load Fail`;

  static LOAD_BY_TOKEN = `[${OneTimeTokenAction.key}] Load by token`;

  static LOAD_APP = `[${OneTimeTokenAction.key}] Load app`;
  static LOAD_APP_SUCCESS = `[${OneTimeTokenAction.key}] Load app success`;
  static LOAD_APP_FAIL = `[${OneTimeTokenAction.key}] Load app fail`;

  static UPDATE_BY_TOKEN = `[${OneTimeTokenAction.key}] Update by token`;
  static UPDATE_BY_TOKEN_SUCCESS = `[${OneTimeTokenAction.key}] Update by token success`;
  static UPDATE_BY_TOKEN_FAIL = `[${OneTimeTokenAction.key}] Update by token fail`;

  static TRANSFER_BY_TOKEN = `[${OneTimeTokenAction.key}] Transfer by token`;
  static TRANSFER_BY_TOKEN_SUCCESS = `[${OneTimeTokenAction.key}] Transfer by token success`;
  static TRANSFER_BY_TOKEN_FAIL = `[${OneTimeTokenAction.key}] Transfer by token fail`;

  public loadSuccess(payload: IPayload, response) {
    return {
      type: OneTimeTokenAction.LOAD_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public loadFail(error: any): IFailAction {
    return {
      type: OneTimeTokenAction.LOAD_FAIL,
      payload: {
        request: null,
        response: error
      }
    };
  }

  public loadByToken(token: string) {
    return {
      type: OneTimeTokenAction.LOAD_BY_TOKEN,
      token: token
    };
  }

  public loadApp(appId: string, fields: CoreField[], token: string, mobileToken: boolean) {
    return {
      type: OneTimeTokenAction.LOAD_APP,
      payload: {
        appId,
        fields,
        token,
        mobileToken
      }
    };
  }

  public loadAppSuccess(payload: IPayload, response) {
    return {
      type: OneTimeTokenAction.LOAD_APP_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public loadAppFail(error: any): IFailAction {
    return {
      type: OneTimeTokenAction.LOAD_APP_FAIL,
      payload: {
        request: null,
        response: error
      }
    };
  }

  public updateByToken(token: string, payload: IPayload) {
    return {
      type: OneTimeTokenAction.UPDATE_BY_TOKEN,
      token: token,
      payload: payload
    };
  }

  public updateByTokenSuccess(response) {
    return {
      type: OneTimeTokenAction.UPDATE_BY_TOKEN_SUCCESS,
      payload: {
        response
      }
    };
  }

  public updateByTokenFail(error: any): IFailAction {
    return {
      type: OneTimeTokenAction.UPDATE_BY_TOKEN_FAIL,
      payload: {
        request: null,
        response: error
    }
    };
  }

  public transferByToken(token: string, transfer: CoreMultiTransfer) {
    /* Manipulate */
    (transfer.create.nodes as TreeNode[]) = (transfer.create.nodes as TreeNode[]).map(treeNode => {
      treeNode.placeholderId = treeNode.id;
      delete treeNode.id;
      return treeNode;
    });
    return {
      type: OneTimeTokenAction.TRANSFER_BY_TOKEN,
      token: token,
      payload: transfer
    };
  }

  public transferByTokenSuccess(response) {
    return {
      type: OneTimeTokenAction.TRANSFER_BY_TOKEN_SUCCESS,
      payload: {
        response
      }
    };
  }

  public transferByTokenFail(error: any): IFailAction {
    return {
      type: OneTimeTokenAction.TRANSFER_BY_TOKEN_FAIL,
      payload: {
        request: null,
        response: error
      }
    };
  }

}
