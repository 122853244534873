import { Injectable } from '@angular/core';
import { JSONAPIResourceService } from '../jsonapi/jsonapi-resource.service';
import { BackendGoService } from './backend.go.service';
import { GetHistoryResponse, INode, INodeResponse } from '../models/node.model';
import {
  IActivity,
  IBusinessarea,
  IEmail,
  IGroup,
  IHierarchy,
  IHumanResource,
  IImportScheme,
  IInstance,
  IModel,
  INodeData,
  INodeStructure,
  IRelationship,
  ISubset, IUser,
  IVersion
} from '../models';
import { JSONAPIResponse } from '../jsonapi';
import { IAPIFindAll, IAPIFindAllCreate } from './api.model';

import { isArray, isNullOrUndefined } from 'util';

@Injectable()
export class GoAPI {

  constructor(private apiService: BackendGoService) { }

  public byBusinessarea(id: number) {
    return new JSONAPIResourceService<INodeResponse>('businessarea', `legacy/businessarea/${id}`, this.apiService);
  }

  public getHistory(id) {
    return new JSONAPIResourceService<GetHistoryResponse>('getHistory', `/api/v5/businessarea/${id}/getHistory`, this.apiService);
  }

  public get models() {
    return new JSONAPIResourceService<IModel>('models', '/api/v5/models', this.apiService);
  }

  public get nodedata() {
    return new JSONAPIResourceService<INodeData>('nodedata', '/api/v5/nodedata', this.apiService);
  }

  public get activities() {
    return new JSONAPIResourceService<IActivity>('activities', '/api/v5/activities', this.apiService);
  }

  public get humanresources() {
    return new JSONAPIResourceService<IHumanResource>('humanresources', '/api/v5/humanresources', this.apiService);
  }

  public get businessareas() {
    return new JSONAPIResourceService<IBusinessarea>('businessarea', '/api/v5/businessareas', this.apiService);
  }

  public get nodestructures() {
    return new JSONAPIResourceService<INodeStructure>('nodestructures', '/api/v5/nodestructures', this.apiService);
  }
  public get instances() {
    return new JSONAPIResourceService<IInstance>('instances', '/api/v5/instances', this.apiService);
  }
  public get relationships() {
    return new JSONAPIResourceService<IRelationship>('relationships', '/api/v5/relationships', this.apiService);
  }

  public get nodesAndRelationships() {
    return new JSONAPIResourceService<any>('nodes-and-relationships', '/api/v5/nodes-and-relationships', this.apiService);
  }

  public import() {
    return new JSONAPIResourceService<INodeResponse>('import', `api/v5/import`, this.apiService);
  }

  public mining(key: string) {
    return new JSONAPIResourceService<INodeResponse>('mining', `legacy/mining/${key}`, this.apiService);
  }

  public guardian() {
    return new JSONAPIResourceService<INodeResponse>('guardian', `api/v5/findInfractions`, this.apiService);
  }

  public my(businessAreaId: string) {
    return new JSONAPIResourceService<INodeResponse>('my', `api/v5/my/` + businessAreaId, this.apiService);
  }

  public ipc() {
    return new JSONAPIResourceService<INodeResponse>('ipc', `legacy/ipc`, this.apiService);
  }

  public me() {
    return new JSONAPIResourceService<IUser>('user', 'api/v5/me', this.apiService);
  }

  public user() {
    return new JSONAPIResourceService<IUser>('user', 'api/v5/user', this.apiService);
  }

  public excel() {
    return new JSONAPIResourceService<any>('excel', 'api/v5/exportExcel', this.apiService);
  }

  public resetNotifications() {
    return new JSONAPIResourceService<INodeResponse>('resetNotifications', 'api/v5/resetNotifications', this.apiService);
  }

  public get hierarchy() {
    return new JSONAPIResourceService<IHierarchy>('hierarchy', '/api/v5/hierarchy', this.apiService);
  }

  public get actions() {
    return new JSONAPIResourceService<IHierarchy>('actions', '/api/v5/actions', this.apiService);
  }

  public get settings() {
    return new JSONAPIResourceService<any>('settings', '/api/v5/settings', this.apiService);
  }

  public getToken() {
    return new JSONAPIResourceService<any>('bytoken', '/api/v5/token', this.apiService);
  }

  public byToken(token: string) {
    return new JSONAPIResourceService<any>('bytoken', '/api/v5/bytoken/' + token, this.apiService);
  }

  public loadApp() {
    return new JSONAPIResourceService<any>('app', '/app/load', this.apiService);
  }

  public transferByToken(token: string) {
    return new JSONAPIResourceService<any>('transferbytoken', '/api/v5/transferbytoken/' + token, this.apiService);
  }

  public get email() {
    return new JSONAPIResourceService<IEmail>('email', '/api/v5/email', this.apiService);
  }

  public get groups() {
    return new JSONAPIResourceService<IGroup>('groups', '/api/v5/groups', this.apiService);
  }

  public model(id: number) {
    const apiService = this.apiService;
    return <{
      submodels: IAPIFindAllCreate<IModel>,
      subsets: IAPIFindAllCreate<ISubset>,
      nodes: IAPIFindAllCreate<INode>,
      nodedata: IAPIFindAllCreate<INodeData>,
      nodestructures: IAPIFindAllCreate<INodeStructure>,
      relationships: IAPIFindAllCreate<IRelationship>,
      versions: IAPIFindAllCreate<IVersion>
      activities: IAPIFindAll<IActivity>
    }>new class {

      public versions = <IAPIFindAllCreate<IVersion>>new JSONAPIResourceService<IVersion>('versions', `/api/v5/models/${id}/versions`, apiService);
      public submodels = <IAPIFindAllCreate<IModel>>new JSONAPIResourceService<IModel>('models', `/api/v5/models/${id}/models`, apiService);
      public subsets = <IAPIFindAllCreate<IModel>>new JSONAPIResourceService<IModel>('subsets', `/api/v5/models/${id}/subsets`, apiService);
      public nodes = <IAPIFindAllCreate<INode>>new JSONAPIResourceService<INode>('nodes', `/api/v5/models/${id}/nodes`, apiService);
      public nodestructures = <IAPIFindAllCreate<INodeStructure>>new JSONAPIResourceService<INodeStructure>('nodestructures', `/api/v5/models/${id}/nodestructures`, apiService);
      public relationships = <IAPIFindAllCreate<IRelationship>>new JSONAPIResourceService<IRelationship>('relationships', `/api/v5/models/${id}/relationships`, apiService);
      public activities = <IAPIFindAll<IActivity>>new JSONAPIResourceService<IActivity>('activities', `/api/v5/models/${id}/activities`, apiService);
    };
  }

  public instance(id: number) {
    const apiService = this.apiService;
    return <{ businessareas: IAPIFindAllCreate<IBusinessarea>, humanresources: IAPIFindAllCreate<IHumanResource>, activities: IAPIFindAllCreate<IActivity>, groups: IAPIFindAllCreate<IGroup> }>new class {
      public activities = <IAPIFindAllCreate<IActivity>>new JSONAPIResourceService<IActivity>('activities', `api/v5/instances/${id}/activities`, apiService);
      public businessareas = <IAPIFindAllCreate<IBusinessarea>>new JSONAPIResourceService<IBusinessarea>('businessareas', `api/v5/instances/${id}/businessareas`, apiService);
      public humanresources = <IAPIFindAllCreate<IHumanResource>>new JSONAPIResourceService<IHumanResource>('humanresources', `api/v5/instances/${id}/humanresources`, apiService);
      public groups = <IAPIFindAllCreate<IGroup>>new JSONAPIResourceService<IGroup>('groups', `api/v5/instances/${id}/groups`, apiService);
    };
  }

  public businessarea(id: number) {
    const apiService = this.apiService;
    return <{ submodels: IAPIFindAllCreate<IModel>, models: IAPIFindAll<IModel>, importschemes: IAPIFindAllCreate<IImportScheme>, tree: any }>new class {
      public submodels = <IAPIFindAllCreate<IModel>>new JSONAPIResourceService<IModel>('models', `api/v5/businessareas/${id}/models`, apiService);
      public importschemes = <IAPIFindAllCreate<IImportScheme>>new JSONAPIResourceService<IImportScheme>('importschemes', `api/v5/businessareas/${id}/importschemes`, apiService);
      public tree = new JSONAPIResourceService<any>('tree', `api/v5/build-tree/${id}`, apiService);
    };
  }

  public massBusinessArea(Id: string) {
    const path = `/api/v5/businessareas/${Id}/mass/`;
    return this.apiService.get(path)
      .map((d: any) => new JSONAPIResponse<any>(d));
  }

  public getEntityPerms(entity: string, page: number) {
    const path = `/api/v5/permissions/${entity}?page=${page}`;
    return this.apiService.get(path);
  }

  public listUsers() {
    const path = '/api/v5/humanresources';
    return this.apiService.get(path);
  }

  public analyseDocument() {
    return new JSONAPIResourceService<any>('analyse', '/ai/v1/documents/analyse', this.apiService);
  }

  public similarity() {
    return new JSONAPIResourceService<any>('similarity', '/ai/v1/similarity', this.apiService);
  }

  public databaseConnection() {
    return new JSONAPIResourceService<any>('databaseConnection', '/api/v5/databaseConnection', this.apiService);
  }

  public runConnector() {
    return new JSONAPIResourceService<any>('runConnector', '/api/v5/connector', this.apiService);
  }

  public authoriseEntry() {
    return new JSONAPIResourceService<any>('authoriseEntry', '/authorisation/token', this.apiService);
  }

  public chatGPT() {
    return new JSONAPIResourceService<any>('chatGPT', '/api/v5/chatGPT', this.apiService);
  }

  public gptRequest() {
    return new JSONAPIResourceService<any>('gptRequest', '/ai/v1/gpt', this.apiService);
  }

  public ingestRequest() {
    return new JSONAPIResourceService<any>('gptRequest', '/ai/v1/ingest', this.apiService);
  }

  public gpt() {
    return new JSONAPIResourceService<any>('gpt', '', this.apiService);
  }

  public search() {
    return new JSONAPIResourceService<any>('search', '/ai/v1/search', this.apiService);
  }

  public findDocuments() {
    return new JSONAPIResourceService<any>('findDocuments', '/ai/v1/findDocuments', this.apiService);
  }
  public stopFindDocuments() {
    return new JSONAPIResourceService<any>('stopFindDocuments', '/ai/v1/stopFindDocuments', this.apiService);
  }

  public uploadDocument() {
    return new JSONAPIResourceService<any>('uploadDocument', '/api/v5/uploadDocument', this.apiService);
  }

  public getDocuments() {
    return new JSONAPIResourceService<any>('getDocuments', '/api/v5/getDocuments', this.apiService);
  }

  public getHealyMembers(start: number, count: number, search?: string) {
    let basePath = '/api/v5/getHealyMembers';
    const getParams = [];
    if (!isNullOrUndefined(start)) {
      getParams.push('start=' + start);
    }
    if (!isNullOrUndefined(count)) {
      getParams.push('count=' + count);
    }
    if (!isNullOrUndefined(search) && search !== '') {
      getParams.push('search=' + encodeURIComponent(search));
    }
    if (getParams.length > 0) {
      basePath += '?' + getParams.join('&');
    }
    return new JSONAPIResourceService<any>('getHealyMembers', basePath, this.apiService);
  }

}
