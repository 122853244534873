import {Injectable} from '@angular/core';
import {SharedService} from '../shared/shared.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.reducer';
import {OneTimeTokenAction} from './onetimetoken.actions';
import * as selectors from './onetimetoken.selectors';
import {List} from 'immutable';
import {OneTimeToken} from './onetimetoken.model';
import { IPayload } from '../shared';
import { CoreField, CoreMultiTransfer } from '../../../core/interface/core.interface';


@Injectable()
export class OneTimeTokenService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: OneTimeTokenAction) {
    super(store, action, selectors);
  }

  public findByElement(elementId: string, elementType: string) {
    return this.all().map((oneTimeTokens: List<OneTimeToken>) => oneTimeTokens
      .filter((tk: OneTimeToken) => tk.elementId === elementId && tk.elementType === elementType).toList());
  }

  public loadByToken(token: string) {
    this.store.dispatch(this.action.loadByToken(token));
  }

  public loadApp(appId: string, fields: CoreField[], token: string, mobileToken: boolean) {
    this.store.dispatch(this.action.loadApp(appId, fields, token, mobileToken));
  }

  public updateByToken(token: string, payload: IPayload) {
    this.store.dispatch(this.action.updateByToken(token, payload));
  }

  public transferByToken(token: string, transfer: CoreMultiTransfer) {
    this.store.dispatch(this.action.transferByToken(token, transfer));
  }

}
