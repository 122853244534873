import { Record, Set, Map } from 'immutable';
import { INodeData, INodeDataAttributes } from '../connector';
import { DataModelState, RecordFactory, RequestDiffRecord } from '../shared';

const nodeDataStateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, NodeData>(),
  relationships: Map<string, NodeDataRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class NodeDataState extends nodeDataStateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, NodeData>;
  relationships: Map<string, NodeDataRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const nodeDataRecord = Record(<INodeDataAttributes>{
  id: undefined,
  name: '',
  reference: '',
  crossReference: '',
  createdAt: undefined,
  description: '',
  description1: '',
  description2: '',
  description3: '',
  description4: '',
  documentUri: '',
  upload_uri: '',
  status: 0,
  responsibleId: null,
  groupId: undefined,
  targetDate: null,
  actualDate: null,
  businessBenefit: 0,
  commercialStatus: 0,
  complexity: 0,
  uncertainty: 0,
  risk: 0,
  sizeType: 0,
  budget: null,
  budgetCalculated: 0,
  budgetActual: 0,
  budgetRemaining: 0,
  benefitBudget: 0,
  benefitActual: 0,
  benefitRemaining: 0,
  costBudget: 0,
  costActual: 0,
  costRemaining: 0,
  investBudget: 0,
  investActual: 0,
  investRemaining: 0,
  businesscalculation: null,
  duplicate_original_id: undefined,
  onreport: true,
  storypoints: 0,
  costtype: 0,
  nodeType: 0,
  sync: undefined,

  startDate: null,
  actualStartDate: null,
  percentageComplete: null,
  priority: null,

  riskProfileCategory: null,
  riskProfileAssessment: null,
  riskProfileCountermeasures: '',
  time: 0,
  timeDescription: '',
  cost: 0,
  costDescription: '',
  quality: 0,
  qualityDescription: '',
  executive: 0,
  executiveDescription: '',
  program: 0,
  programDescription: '',
  functional: 0,
  functionalDescription: '',
  resource: 0,
  resourceDescription: '',
  organizational: 0,
  organizationalDescription: '',
  technical: 0,
  technicalDescription: '',
  updatedAt: undefined,

  creationDate: undefined,
  lastUpdated: undefined,
  currency: '',

  alsoPublishedAs: '',
  cpc: '',
  cpc4: '',
  ipc: '',
  ipc4: '',
  applicants: '',
  techAreas: '',
  techFields: '',
  authorities: '',

  color: '',
  widget: '',
  icon: '',
  obligatory: false,

  workFlowModel: 0,
  workFlowOperation: '',
  workFlowIfClause: '',
  workFlowId: '',
  workFlowFormId: '',
  workFlowFormParams: '',
  workFlowLink: '',
  workFlowTabId: '',

  formId: '',
  formFieldId: '',
  formFieldType: '',
  formFieldControlType: '',
  formFieldDropdownValue: '',
  formFieldCalculation: '',
  formFieldEditable: false,
  formFieldSortable: true,
  formFieldFilterable: false,
  formFieldFixed: false,
  formFieldSearchable: false,
  formFieldWidth: null,
  formFieldShape: '',
  formFieldBucketId: '',
  formBucket: false,
  form_default_value: '',
  dashboardCols: null,
  dashboardRows: null,
  dashboardX: null,
  dashboardY: null,
  fieldConversion: null,
  fieldCreateType: null,
  fieldSkipIfExists: false,
  colorLabelProvider: null,

  fullscreen: false,
  printable: false,
  hideWidget: false,

  ai_type: '',
  ai_preset: '',

  numberField1: 0,
  numberField2: 0,
  numberField3: 0,
  numberField4: 0,
  numberField5: 0,
  numberField6: 0,
  numberField7: 0,
  numberField8: 0,
  numberField9: 0,
  numberField10: 0,
  textField1: '',
  textField2: '',
  textField3: '',
  textField4: '',
  textField5: '',
  textField6: '',
  textField7: '',
  textField8: '',
  textField9: '',
  textField10: 0,
  textField11: '',
  textField12: '',
  textField13: '',
  textField14: '',
  textField15: '',
  textField16: '',
  textField17: '',
  textField18: '',
  textField19: '',
  textField20: '',
  textField21: '',
  textField22: '',
  textField23: '',
  textField24: '',
  textField25: '',
  textField26: '',
  textField27: '',
  textField28: '',
  textField29: '',
  textField30: '',
  textField31: '',
  textField32: '',
  textField33: '',
  textField34: '',
  textField35: '',
  textField36: '',
  textField37: '',
  textField38: '',
  textField39: '',
  textField40: '',

  formFieldValidation: '',
  formFieldValidationMessage: '',
  bestCase: '',
  aiService: '',
  email: '',
  externalInput: '',
  code: '',

  floatField1: 0,
  floatField2: 0,
  floatField3: 0,
  floatField4: 0,
  floatField5: 0,
  floatField6: 0,
  floatField7: 0,
  floatField8: 0,
  floatField9: 0,
  floatField10: 0,

  showOnlyOnMobile: false,
  hideOnMobile: false,
  partOfTRT: false,

  refreshId: '',

  relationships: undefined
});

export class NodeData extends nodeDataRecord implements INodeDataAttributes {
  id: string;
  name: string;
  reference: string;
  crossReference: string;
  createdAt: number;
  description: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  documentUri: string;
  upload_uri: string;
  status: number;
  responsibleId: number;
  groupId: number;
  targetDate: string;
  actualDate: string;
  businessBenefit: number;
  commercialStatus: number;
  complexity: number;
  uncertainty: number;
  risk: number;
  sizeType: number;
  budget: number;
  budgetCalculated: number;
  budgetActual: number;
  budgetRemaining: number;
  benefitBudget: number;
  benefitActual: number;
  benefitRemaining: number;
  costBudget: number;
  costActual: number;
  costRemaining: number;
  investBudget: number;
  investActual: number;
  investRemaining: number;
  businesscalculation: string;
  duplicate_original_id?: number;
  onreport: boolean;
  storypoints: number;
  costtype: number;
  nodeType: number;
  sync?: any;

  startDate?: string;
  actualStartDate?: string;
  percentageComplete?: number;
  priority?: number;

  riskProfileCategory?: number;
  riskProfileAssessment?: number;
  riskProfileCountermeasures?: string;
  time?: number;
  timeDescription?: string;
  cost?: number;
  costDescription?: string;
  quality?: number;
  qualityDescription?: string;
  executive?: number;
  executiveDescription?: string;
  program?: number;
  programDescription?: string;
  functional?: number;
  functionalDescription?: string;
  resource?: number;
  resourceDescription?: string;
  organizational?: number;
  organizationalDescription?: string;
  technical?: number;
  technicalDescription?: string;

  updatedAt: number;

  creationDate: number;
  lastUpdated: number;
  currency: string;

  alsoPublishedAs?: string;
  cpc?: string;
  cpc4?: string;
  ipc?: string;
  ipc4?: string;
  applicants?: string;
  techAreas?: string;
  techFields?: string;
  authorities?: string;

  color?: string;
  widget?: string;
  icon?: string;
  obligatory?: boolean;

  workFlowModel?: number;
  workFlowOperation?: string;
  workFlowIfClause?: string;
  workFlowId?: string;
  workFlowFormId?: string;
  workFlowFormParams?: string;
  workFlowLink?: string;
  workFlowTabId?: string;

  formId: string;
  formFieldId: string;
  formFieldType: string;
  formFieldControlType: string;
  formFieldDropdownValue: string;
  formFieldEditable: boolean;
  formFieldSortable: boolean;
  formFieldFilterable: boolean;
  formFieldFixed: boolean;
  formFieldSearchable: boolean;
  formFieldWidth: string;
  formFieldBucketId: string;
  formFieldShape: string;
  formBucket: boolean;
  formFieldCalculation: string;
  form_default_value: string | number;

  dashboardCols: number;
  dashboardRows: number;
  dashboardX: number;
  dashboardY: number;

  fieldConversion: string;
  fieldCreateType: string;
  fieldSkipIfExists: boolean;
  colorLabelProvider: string;

  fullscreen: boolean;
  printable: boolean;
  hideWidget: boolean;

  numberField1: number;
  numberField2: number;
  numberField3: number;
  numberField4: number;
  numberField5: number;
  numberField6: number;
  numberField7: number;
  numberField8: number;
  numberField9: number;
  numberField10: number;
  textField1: string;
  textField2: string;
  textField3: string;
  textField4: string;
  textField5: string;
  textField6: string;
  textField7: string;
  textField8: string;
  textField9: string;
  textField10: number;
  textField11: string;
  textField12: string;
  textField13: string;
  textField14: string;
  textField15: string;
  textField16: string;
  textField17: string;
  textField18: string;
  textField19: string;
  textField20: string;
  textField21: string;
  textField22: string;
  textField23: string;
  textField24: string;
  textField25: string;
  textField26: string;
  textField27: string;
  textField28: string;
  textField29: string;
  textField30: string;
  textField31: string;
  textField32: string;
  textField33: string;
  textField34: string;
  textField35: string;
  textField36: string;
  textField37: string;
  textField38: string;
  textField39: string;
  textField40: string;
  formFieldValidation: string;
  formFieldValidationMessage: string;
  aiService: string;
  email: string;
  externalInput: string;
  code: string;
  bestCase: number;

  floatField1: number;
  floatField2: number;
  floatField3: number;
  floatField4: number;
  floatField5: number;
  floatField6: number;
  floatField7: number;
  floatField8: number;
  floatField9: number;
  floatField10: number;

  showOnlyOnMobile: boolean;
  hideOnMobile: boolean;
  partOfTRT: boolean;

  refreshId: string;

  childrenRelationshipId: any;
  parentsRelationshipId: any;

  ai_type: string;
  ai_preset: string;

  relationships: NodeDataRelationships;

  static fromJSONAPI(nodedata: INodeData) {
    return RecordFactory.entity.fromJSONAPI<NodeData>(NodeData, nodedata);
  }
}

const relationshipsRecord = Record({
  activities: undefined,
  businessarea: undefined,
  instance: undefined,
  models: undefined,
  nodestructures: undefined,
  created: undefined,
  relatedChildren: Set<number>(),
  relatedParents: Set<number>()
});

export class NodeDataRelationships extends relationshipsRecord {
  activities: string[];
  models: string[];
  nodestructures: string[];
  businessarea: string;
  instance: string;
  created: string[];
  relatedChildren: number[] | Set<number>;
  relatedParents: number[] | Set<number>;

  static fromJSONAPI(nodedata: INodeData) {
    return RecordFactory.relationships.fromJSONAPI<NodeDataRelationships>(NodeDataRelationships, nodedata);
  }
}
