import { SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

export interface GPT {
  uuid: string;
  type: string;
  url: string;
  ip: string;
  username: string;
  pem: string;
  autogen: string[];
  user_id: string;
  documents: string[];
  events: BehaviorSubject<GPTResponse>;
}

export interface GPTResponse {
  gpt: GPT;
  status: string;
  message: GPTMessage;
}

export interface GPTMessage {
  uuid: string;
  message: string;
  prompt?: string;
  status: string;
  response?: string | SafeHtml;
  originalResponse?: string;
  sources?: PrivateGPTCompletionResponseChoiceSource[];
  agentMessages?: AutogenAgentResponseMessage[];
  nodeIds?: string[];
  nodeFields?: string[];
}

export const GPTStatus = {
  CONNECTING: 'connecting',
  IDLE: 'idle',
  STARTED: 'started',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
};

export interface GPTIngested {
  name: string;
  type: string;
  selected?: boolean;
  pages: GPTIngestedPage[];
}

export interface GPTIngestedPage {
  id: string;
  page: string;
}

export interface GPTRequest {
  type: string;
  privateGPTHealthRequest: PrivateGPTHealthRequest | null;
  privateGPTCompletionRequest: PrivateGPTCompletionRequest | null;
  uKnowCompletionRequest: UKnowCompletionRequest | null;
  privateGPTIngestedRequest: PrivateGPTIngestedRequest | null;
  privateGPTIngestFileRequest: PrivateGPTIngestFileRequest |null;
  privateGPTIngestText: PrivateGPTIngestTextRequest |null;
  privateGPTIngestDelete: PrivateGPTIngestDeleteRequest |null;
  autogenRequest: AutogenRequest | null;
}

export interface PrivateGPTHealthRequest {
  url: string;
}

export interface PrivateGPTCompletionRequest {
  url: string;
  type: string;
  prompt: string | null;
  messages: PrivateGPTCompletionRequestMessage[] | null;
  system_prompt: string;
  use_context: boolean;
  context_filter: PrivateGPTCompletionRequestFilter;
  include_sources: boolean;
  stream: boolean;
  nodeIds: string[];
  nodeFields: string[];
  externalSystems: string[];
}

export interface PrivateGPTCompletionRequestMessage {
  role: string;
  content: string;
}

export interface PrivateGPTCompletionRequestFilter {
  docs_ids: string[];
}

export interface PrivateGPTCompletionResponse {
  id: string;
  object: string;
  created: number;
  model: any;
  choices: PrivateGPTCompletionResponseChoice[];
}

export interface PrivateGPTCompletionResponseChoice {
  finish_reason: string;
  delta: PrivateGPTCompletionResponseChoiceDelta;
  message: PrivateGPTCompletionResponseChoiceMessage;
  sources: PrivateGPTCompletionResponseChoiceSource[];
  index: number;
}

export interface PrivateGPTCompletionResponseChoiceDelta {
  content: string;
}

export interface PrivateGPTCompletionResponseChoiceMessage {
  role: string;
  content: string;
}

export interface PrivateGPTCompletionResponseChoiceSource {
  object: any;
  score: number;
  document: PrivateGPTCompletionResponseChoiceSourceDocument;
  text: string;
  previous_texts: string[];
  next_texts: string[];
}

export interface PrivateGPTCompletionResponseChoiceSourceDocument {
  object: any;
  doc_id: string;
  doc_metadata: PrivateGPTCompletionResponseChoiceSourceDocumentMeta;
}

export interface PrivateGPTCompletionResponseChoiceSourceDocumentMeta {
  page_label: string;
  file_name: string;
}

export interface PrivateGPTIngestedRequest {
  url: string;
}

export interface PrivateGPTIngestedResponse {
  object: string;
  model: any;
  data: PrivateGPTIngestedResponseDatum[];
}

export interface PrivateGPTIngestedResponseDatum {
  object: string;
  doc_id: string;
  doc_metadata: PrivateGPTCompletionResponseChoiceSourceDocumentMeta;
}

export interface PrivateGPTIngestFileRequest {
  url: string;
  file: File;
}

export interface PrivateGPTIngestTextRequest {
  url: string;
  file_name: string;
  text: string;
}

export interface PrivateGPTIngestDeleteRequest {
  url: string;
  doc_ids: string[];
}

export interface AutogenResponse {
  status: boolean;
  message: string;
  data: any;
}

export interface AutogenRequest {
  user_id: string;
  connection_id?: string;
  url: string;
  method: string;
  message?: AutogenMessage;
  skill?: AutogenSkill;
  model?: AutogenModel;
  agent?: AutogenAgent;
  workflow?: AutogenAgentWorkflow;
  session_id?: string;
  session?: AutogenSession;
}

export interface AutogenMessage {
  content: string;
  msg_id: string;
  role: string;
  root_msg_id: string;
  session_id: string;
  user_id: string;
  nodeIds?: string[];
  nodeFields?: string[];
}

export interface AutogenSession {
  id?: string;
  timestamp?: string;
  user_id: string;
  flow_config: AutogenAgentWorkflow;
}

export interface AutogenSkill {
  content: string;
  description: string;
  title: string;
  file_name?: string;
  id?: string;
  timestamp?: string;
  user_id: string;
}

export interface AutogenModel {
  id?: string;
  api_key: string;
  api_type: string;
  api_version: string;
  base_url: string;
  description: string;
  model: string;
  user_id: string;
}

export interface AutogenAgent {
  id?: string;
  description: string;
  type: string;
  user_id: string;
  config: AutogenAgentConfig;
  skills: AutogenSkill[];
}

export interface AutogenAgentConfig {
  id?: string;
  code_execution_config: boolean;
  default_auto_reply: string;
  human_input_mode: string;
  max_consecutive_auto_reply: number;
  name: string;
  system_message: string;
  is_termination_msg: string;
  llm_config: AutogenAgentConfigLLMConfig | false;
}

export interface AutogenAgentConfigLLMConfig {
  cache_seed: boolean;
  temperature: number;
  timeout: number;
  response_format?: any;
  config_list: AutogenAgentConfigLLMConfigModel[];
}

export interface AutogenAgentConfigLLMConfigModel {
  model: string;
}

export interface AutogenAgentWorkflow {
  id?: string;
  description: string;
  name: string;
  type: string;
  user_id?: string;
  timestamp?: string;
  summary_method?: string;
  receiver?: AutogenAgentWorkflowConfig;
  sender?: AutogenAgentWorkflowConfig;
}

export interface AutogenAgentWorkflowConfig {
  config: AutogenAgentConfig;
  type: string;
}

export interface AutogenAgentResponseMessage {
  recipient: string;
  sender: string;
  message: AutogenAgentResponseMessageMessage;
  timestamp: string;
  sender_type: string;
  connection_id: string;
  message_type: string;
}

export interface AutogenAgentResponseMessageMessage {
  content: string | SafeHtml;
  role: string;
}

export interface UKnowCompletionRequest {
  url: string;
  type: string;
  prompt: string | null;
  messages: UKnowCompletionRequestMessage[] | null;
  system_prompt: string;
  use_context: boolean;
  context_filter: UKnowCompletionRequestFilter;
  include_sources: boolean;
  stream: boolean;
  nodeIds: string[];
  nodeFields: string[];
  externalSystems: string[];
  token: string;
}
export interface UKnowCompletionRequestMessage {
  role: string;
  content: string;
}
export interface UKnowCompletionRequestFilter {
  docs_ids: string[];
}
export interface UKnowCompletionResponse {
  id: string;
  object: string;
  created: number;
  model: any;
  choices: UknowCompletionResponseChoice[];
}
export interface UknowCompletionResponseChoice {
  finish_reason: string;
  message: UKnowCompletionResponseChoiceMessage;
  index: number;
  sources: PrivateGPTCompletionResponseChoiceSource[];
}
export interface UKnowCompletionResponseChoiceMessage {
  role: string;
  content: string;
  sources: PrivateGPTCompletionResponseChoiceSource[];
}