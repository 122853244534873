import { TranslateService } from '@ngx-translate/core';

import { CoreService } from '../../core/service/core.service';
import { CoreUtilities } from '../../core/utilities/core.utilities';
import {
  GPT,
  GPTMessage,
  GPTRequest,
  GPTResponse,
  GPTStatus,
  UKnowCompletionRequest,
  UKnowCompletionRequestMessage,
  UKnowCompletionResponse,
} from './gpt.interface';
import { TreeNode } from '../../core/interface/core.interface';

export class GptUKnowService {


  public constructor(private coreService: CoreService, private coreUtilities: CoreUtilities, private translateService: TranslateService) {}

  /**
   * Connect
   *
   * @param {GPT} gpt
   */
  public connect(gpt: GPT) {
    /* Manipulate the uuid */
    gpt.uuid = gpt.uuid.replace('-', '').slice(0, 11);
    /* Send out the idle status */
    gpt.events.next({ gpt, status: GPTStatus.IDLE } as GPTResponse);
  }

  /**
   * Send prompt
   *
   * @param {GPT} gpt
   * @param promptId
   * @param prompt
   * @param conversations
   * @param systemPrompt
   * @param mode
   * @param userDocumentIds
   * @param externalSystems
   * @param model
   */
  public sendPrompt(gpt: GPT, promptId: string, prompt: string, conversations: GPTMessage[], systemPrompt?: string, mode?: string, userDocumentIds?: string[], externalSystems?: TreeNode[], model?: TreeNode) {
    /* Convert it to messages */
    const messages = this.convertGPTMessagesToUKnowMessage(conversations);

    if (systemPrompt !== undefined) {
      messages.unshift({ role: 'system', content: systemPrompt } as UKnowCompletionRequestMessage);
    }

    /* Get node ids and node fields if they are there */
    let nodeIds: string[];
    let nodeFields: string[];
    const count = conversations.length;
    for (let i = 0; i < count; i++) {
      const conversation = conversations[i];

      /* Node ids */
      if (conversation.nodeIds !== undefined) {
        if (nodeIds === undefined) {
          nodeIds = conversation.nodeIds;
        } else {
          nodeIds = nodeIds.concat(conversation.nodeIds);
        }
      }

      /* Node fields */
      if (conversation.nodeFields !== undefined) {
        if (nodeFields === undefined) {
          nodeFields = conversation.nodeFields;
        } else {
          nodeFields = nodeFields.concat(conversation.nodeFields);
        }
      }
    }

    /* Set up the prompt */
    const request = {
      type: 'uknow',
      uKnowCompletionRequest: {
        type: 'chat',
        url: model !== undefined ? model.documentUri : gpt.url,
        messages,
        use_context: true,
        include_sources: mode === 'Query Docs',
        nodeIds,
        nodeFields,
        token: gpt.user_id
      } as UKnowCompletionRequest
    } as GPTRequest;

    if (userDocumentIds !== undefined && request.uKnowCompletionRequest.use_context) {
      request.uKnowCompletionRequest.context_filter = {
        docs_ids: userDocumentIds
      };
    }

    if (externalSystems !== undefined && request.uKnowCompletionRequest.use_context) {
      request.uKnowCompletionRequest.externalSystems = externalSystems.map(es => es.id);
    }

    /* Send the prompt */
    this.coreService.gpt('completion', request).filter(response => response.choices !== undefined).take(1).subscribe((response) => {
      /* Send the event via emitter */
      gpt.events.next({ gpt, message: this.convertToGPTMessage(promptId, prompt, response), status: this.convertToGPTStatus(response) } as GPTResponse);
    });

  }


  /**
   * Convert a private gpt message to general GPT message
   *
   * @param {GPTMessage[]} gptMessages
   * @returns {any[]}
   * @private
   */
  private convertGPTMessagesToUKnowMessage(gptMessages: GPTMessage[]) {
    /* Convert conversations to chat */
    const messages = [];

    /* Loop over messages */
    const count = gptMessages.length;
    for (let i = 0; i < count; i++) {
      const gptMessage = gptMessages[i];
      messages.push({ role: 'user', content: gptMessage.prompt } as UKnowCompletionRequestMessage);
      if (gptMessage.response !== undefined && gptMessage.response !== '') {
        messages.push({ role: 'assistant', content: gptMessage.originalResponse } as UKnowCompletionRequestMessage);
      }
    }

    /* Return messages */
    return messages;
  }

  /**
   * Convert the data object to GPT message
   *
   * @param {string} promptId
   * @param prompt
   * @param response
   * @returns {GPTMessage}
   * @private
   */
  private convertToGPTMessage(promptId: string, prompt: string, response: UKnowCompletionResponse): GPTMessage {
    /* Stop if it's not completion */
    if (response.choices === undefined) {
      return { uuid: promptId, prompt, message: '', status: GPTStatus.PROCESSING, sources: [] };
    }

    /* The message */
    const message: GPTMessage = { uuid: promptId, prompt, message: '', status: this.convertToGPTStatus(response), sources: response.choices[0].sources };

    /* Add choices */
    message.response = response.choices[0].message.content;

    /* Return message */
    return message;
  }

  /**
   * Convert event data to GPT Status
   *
   * @returns {string}
   * @private
   * @param response
   */
  private convertToGPTStatus(response: UKnowCompletionResponse): string {
    switch (response.choices[0].finish_reason) {
      case 'stop':
        return GPTStatus.COMPLETED;
    }
    return '';
  }

}
