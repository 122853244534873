import { Map, OrderedMap, Set } from 'immutable';
import { isArray, isNumber } from 'util';

import { TreeNode, TreeRelationship } from '../../core/interface/core.interface';
import { NODES_TYPE_DEFAULT } from '../../shared/api/nodes/nodes.models';
import { UUID } from 'angular2-uuid';
import { AppGlobal } from '../../app.global';

export class Traverser {

  /* Lanes */
  private lanes: TreeNode[] = [];

  /* Lane */
  private lane: TreeNode;
  private tempLane: TreeNode;

  /* TRT */
  private useTRT = false;
  private trtMap = {};

  /* Parent node */
  private parentNode: TreeNode;

  /* Re visit */
  private revisit = true;

  /* unfiltered children */
  private unfilteredChildren = false;

  /* Node type restrictions */
  private nodeTypeRestrictions = {};

  /* Relationship weights */
  private relationshipWeights: OrderedMap<string, number>;

  /**
   * Run the traverser
   *
   * @param treeNodes
   * @param direction
   */
  public run(treeNodes: TreeNode[], direction?: 'children' | 'parents') {
    /* Clear */
    this.trtMap = {};
    /* Expecting only one lane */
    if (this.lane !== undefined) {
      return this.traverse(treeNodes, [this.lane], undefined, direction);
    } else {
      return this.traverse(treeNodes, this.lanes, undefined, direction);
    }
  }

  /**
   * Utilize the traverser TRT function to build a virutal structure of nodes
   *
   * @param treeNodes
   * @param init
   * @param result
   */
  public getStructure(treeNodes: TreeNode[], init = true, result = { nodes: [] as TreeNode[], relationships: [] }) {
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      const clonedTreeNode = Map(treeNode).set('children', []).set('unfilteredChildren', []).set('parents', []).set('unfilteredParents', []).toJS();
      /* Add to result */
      result.nodes.push(clonedTreeNode);
      if (this.trtMap[clonedTreeNode.id] !== undefined) {
        /* Get the children */
        const childrenResult = this.getStructure(this.trtMap[clonedTreeNode.id].childIds.map(id => AppGlobal.treeNodes.get(id)), false);
        /* Store the result as children */
        clonedTreeNode.children = clonedTreeNode.unfilteredChildren = childrenResult.nodes.map(childNode => {
          /* Iterate over children to set parent */
          childNode.parents.push(clonedTreeNode);
          return childNode;
        });
        /* Merge the relationships */
        result.relationships = result.relationships.concat(childrenResult.relationships);
        /* Iterate over children to build relationships */
        const count2 = childrenResult.nodes.length;
        for (let i2 = 0; i2 < count2; i2++) {
          const childNode = childrenResult.nodes[i2];
          result.relationships.push({ id: UUID.UUID(), parentId: clonedTreeNode.id, childId: childNode.id, weight: 1 } as TreeRelationship);
        }
      }
    }
    if (init) {
      /* Clear duplicate relationships */
      const relationshipIds = [];
      const relationships = [];
      const count4 = result.relationships.length;
      for (let i4 = 0; i4 < count4; i4++) {
        const relationship = result.relationships[i4] as TreeRelationship;
        const id = relationship.parentId + '-' + relationship.childId;
        if (relationshipIds.indexOf(id) !== -1) {
          continue;
        }
        relationshipIds.push(id);
        relationships.push(relationship);
      }
      result.relationships = relationships;
    }
    return result;
  }

  /**
   * Set the usage of TRT
   *
   * @param useTRT
   */
  public setTRT(useTRT: boolean): Traverser {
    this.useTRT = useTRT;
    return this;
  }

  /**
   * Set the usage of TRT
   *
   * @param treeNodes
   * @param visited
   */
  public setInternalTRT(treeNodes: TreeNode[], visited = {}): TreeNode[] {
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (visited[treeNode.id] !== undefined) {
        continue;
      }
      visited[treeNode.id] = true;
      if (this.trtMap[treeNode.id] !== undefined) {
        treeNode.trtMap = this.trtMap[treeNode.id];
      } else {
        treeNode.trtMap =  { parentIds: [], childIds: [], modelId: treeNode.modelId };
      }
      if (treeNode.children !== undefined) {
        this.setInternalTRT(treeNode.children, visited);
      }
    }
    return treeNodes;
  }

  /**
   * Clear internal TRT
   *
   */
  public clearInternalTRT() {
    Map(this.trtMap).forEach((_, k) => {
      /* Get the tree node */
      if (AppGlobal.treeNodes.has(k)) {
        const treeNode = AppGlobal.treeNodes.get(k);
        treeNode.trtMap = undefined;
        AppGlobal.treeNodes.set(treeNode.id, treeNode);
      }
    });
  }

  /**
   * Set parent node
   *
   * @param parentNode
   */
  public setParentNode(parentNode: TreeNode): Traverser {
    this.parentNode = parentNode;
    return this;
  }

  /**
   * Define restrictions per node type
   *
   * @param nodeType
   * @param restrictions
   */
  public setRestrictions(nodeType: number, restrictions: string[]): Traverser {
    this.nodeTypeRestrictions[nodeType] = Set(restrictions);
    return this;
  }

  /**
   * Define if a node can be revisited
   *
   * @param revisit
   */
  public setRevisit(revisit: boolean): Traverser {
    this.revisit = revisit;
    return this;
  }

  public setUnfiltered(unfiltered: boolean): Traverser {
    this.unfilteredChildren = unfiltered;
    return this;
  }

  /**
   * Set the relationship weights subject
   *
   * @param relationshipWeights
   */
  public setRelationshipWeights(relationshipWeights): Traverser {
    relationshipWeights.subscribe(weights => {
      this.relationshipWeights = weights;
    });
    return this;
  }

  /**
   * Get the ids of nodes that are used for TRT
   *
   */
  public getTRT() {
    return this.trtMap;
  }

  /**
   * Add tree node to lanes
   *
   * @param treeNode
   */
  public addToLanes(treeNode: TreeNode | TreeNode[]): Traverser {
    if (Array.isArray(treeNode)) {
      this.lanes.push(...treeNode);
    } else {
      this.lanes.push(treeNode);
    }
    return this;
  }

  /**
   * Add a lane by node type
   *
   * @param nodeType
   * @param included
   * @param distance
   * @param goBack
   */
  public addToLane(nodeType: number | number[], included = false, distance = 1): Traverser {
    if (this.lane === undefined) {
      this.lane = { id: UUID.UUID(), nodeType: isArray(nodeType) ? nodeType[0] : nodeType, hideWidget: !included, children: [] } as TreeNode;
      this.tempLane = this.lane;
    } else {
      nodeType = isArray(nodeType) ? nodeType : [nodeType];
      const count = nodeType.length;
      let lane: TreeNode;
      for (let i = 0; i < count; i++) {
        lane = { id: UUID.UUID(), nodeType: nodeType[i], hideWidget: !included, children: [] } as TreeNode;
        this.tempLane.children.push(lane);
        if (this.relationshipWeights === undefined) {
          this.relationshipWeights = Map<string, number>();
        }
        this.relationshipWeights = this.relationshipWeights.set(this.tempLane.id + '-' + lane.id, distance);
      }
      this.tempLane = lane;
    }
    return this;
  }

  /**
   * Manipulate lanes
   *
   * @param {Function} callback
   * @param lanes
   * @param direction
   */
  public manipulateLanes(callback: Function, lanes = this.lanes, direction = 'children') {
    const count = lanes.length;
    for (let i = 0; i < count; i++) {
      const lane = callback(lanes[i]);
      this.manipulateLanes(callback, lane[direction], direction);
    }
  }

  /**
   * Get lanes
   *
   * @returns {TreeNode[]}
   */
  public getLanes() {
    return this.lanes;
  }

  /**
   * Get the leaves of a tree node
   *
   * @param treeNodes
   * @param leaves
   */
  public getLeaves(treeNodes: TreeNode[], leaves = []) {
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (treeNode.children === undefined || treeNode.children === null || treeNode.children.length === 0) {
        leaves.push(treeNode);
      } else {
        leaves = this.getLeaves(treeNode.children, leaves);
      }
    }
    return leaves;
  }

  /**
   * Modify a lane to point from a lane leaf to an obligatory node
   *
   * @param treeNodes
   */
  public setLeafParents(treeNodes: TreeNode[]) {
    const result = [];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = Map(treeNodes[i]).set('hideWidget', !treeNodes[i].obligatory).toJS();
      if (treeNode.parents !== undefined && treeNode.parents !== null) {
        treeNode.parents = treeNode.obligatory ? [] : this.setLeafParents(treeNode.parents);
      }
      result.push(treeNode);
    }
    return result;
  }

  /**
   * Modify a lane to point to an obligatory node and stop there
   * @param treeNodes
   */
  public setLeafChildren(treeNodes: TreeNode[]) {
    const result = [];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = Map(treeNodes[i]).set('hideWidget', !treeNodes[i].obligatory).toJS();
      if (treeNode.children !== undefined && treeNode.children !== null) {
        treeNode.children = treeNode.obligatory ? [] : this.setLeafChildren(treeNode.children);
      }
      result.push(treeNode);
    }
    return result;
  }

  /**
   * The actual traversing function
   *
   * @param treeNodes
   * @param lanes
   * @param direction
   * @param previousLane
   * @param result
   * @param visited
   * @param parentTreeNode
   * @private
   */
  private traverse(treeNodes: TreeNode[], lanes: TreeNode[], previousLane?: TreeNode | number, direction = 'children', result = [], visited = [], parentTreeNode?: TreeNode) {
    const treeNodesCount = treeNodes.length;
    /* Loop over lanes to decide what to show */
    const count = lanes.length;
    for (let i = 0; i < count; i++) {
      /* The lane */
      const lane = lanes[i];
      /* Filter the tree nodes by node type */
      for (let j = 0; j < treeNodesCount; j++) {
        /* The tree node */
        const treeNode = treeNodes[j];
        if (treeNode === undefined || treeNode === null) {
          continue;
        }
        if (this.nodeTypeRestrictions !== undefined && this.nodeTypeRestrictions[treeNode.nodeType] !== undefined && !this.nodeTypeRestrictions[treeNode.nodeType].has(treeNode.id)) {
          continue;
        }
        /* Parent node */
        if (this.parentNode !== undefined && previousLane !== undefined && (previousLane as TreeNode).nodeType === this.parentNode.nodeType) {
          this.trtMap[this.parentNode.id] = { parentIds: [], childIds: [treeNode.id], modelId: this.parentNode.modelId };
        }

        let distance = 1;
        if (previousLane !== undefined) {
          if (isNumber(previousLane)) {
            distance = previousLane;
          } else if (this.relationshipWeights !== undefined) {
            const weight = this.relationshipWeights.get(previousLane.id + '-' + lane.id);
            if (weight !== undefined) {
              distance = weight;
            }
          }
        }

        if (treeNode.nodeType !== lane.nodeType && distance > 1) {
          /* Now get the connected elements without already visited ones */
          const connected = treeNode[this.unfilteredChildren ? 'unfilteredChildren' : 'children'].concat(treeNode[this.unfilteredChildren ? 'unfilteredParents' : 'parents']).filter(next => visited.indexOf(next.id) === -1 && (parentTreeNode === undefined || next.id !== parentTreeNode.id));
          /* Now continue */
          result = this.traverse(connected, [lane], distance - 1, direction, result, visited, parentTreeNode);
          /* Stop it here */
          continue;
        }
        /* Check if the tree node is visible */
        if (treeNode.nodeType === lane.nodeType || lane.nodeType === NODES_TYPE_DEFAULT) {
          /* Add tree node to visited */
          if (!this.revisit) {
            visited.push(treeNode.id);
          }
          let _parentTreeNode = parentTreeNode;
          /* Check if this node should be added to result */
          if (!lane.hideWidget) {
            /* TRT */
            if (this.useTRT && parentTreeNode !== undefined) {
              if (this.trtMap[parentTreeNode.id] === undefined) {
                this.trtMap[parentTreeNode.id] = { parentIds: [], childIds: [], modelId: treeNode.modelId };
              }
              if (this.trtMap[parentTreeNode.id].childIds.indexOf(treeNode.id) === -1) {
                this.trtMap[parentTreeNode.id].childIds.push(treeNode.id);
              }
              if (this.trtMap[treeNode.id] === undefined) {
                this.trtMap[treeNode.id] = { parentIds: [], childIds: [], modelId: treeNode.modelId };
              }
              if (this.trtMap[treeNode.id].parentIds.indexOf(parentTreeNode.id) === -1) {
                this.trtMap[treeNode.id].parentIds.push(parentTreeNode.id);
              }
            }
            /* Set parent node */
            _parentTreeNode = treeNode;
            /* Add to result */
            result.push(treeNode);
          }
          /* If children is missing */
          if (treeNode.children === undefined) {
            treeNode.children = [];
          }
          /* Now get the connected elements without already visited ones */
          const connected = treeNode[this.unfilteredChildren ? 'unfilteredChildren' : 'children'].concat(treeNode[this.unfilteredChildren ? 'unfilteredParents' : 'parents']).filter(next => next !== undefined && visited.indexOf(next.id) === -1);
          /* Now continue */
          result = this.traverse(connected, lane[direction], lane, direction, result, visited, _parentTreeNode);
        }
      }
    }
    /* Return result */
    return result;

  }

}
