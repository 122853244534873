import { TranslateService } from '@ngx-translate/core';
import { Map } from 'immutable';

import { CoreService } from '../../core/service/core.service';
import { CoreUtilities } from '../../core/utilities/core.utilities';
import {
  GPT,
  GPTIngested,
  GPTIngestedPage,
  GPTMessage,
  GPTRequest,
  GPTResponse,
  GPTStatus,
  PrivateGPTCompletionRequest,
  PrivateGPTCompletionRequestMessage,
  PrivateGPTCompletionResponse, PrivateGPTHealthRequest,
  PrivateGPTIngestDeleteRequest,
  PrivateGPTIngestedRequest,
  PrivateGPTIngestedResponse,
} from './gpt.interface';
import { environment } from '../../../environments/environment';
import { TreeNode } from '../../core/interface/core.interface';
import { AppGlobal } from '../../app.global';

export class GptPrivateGPTService {

  private eventSource: EventSource;

  /* External systems */
  private externalSystems: TreeNode[];

  public constructor(private coreService: CoreService, private coreUtilities: CoreUtilities, private translateService: TranslateService) {}

  /**
   * Connect
   *
   * @param {GPT} gpt
   */
  public connect(gpt: GPT) {
    /* Manipulate the uuid */
    gpt.uuid = gpt.uuid.replace('-', '').slice(0, 11);

    /* Do the first request */
    const request = {
      type: 'privategpt',
      privateGPTHealthRequest: {
        url: gpt.url + '/health',
      } as PrivateGPTHealthRequest
    } as GPTRequest;
    this.coreService.gpt('health', request).take(1).subscribe(response => {
      switch (response.status) {
        case 'ok':
          /* Send first update */
          gpt.events.next({ gpt, status: GPTStatus.IDLE } as GPTResponse);
          break;
        default:
          this.coreService.toast(this.translateService.instant('GENERAL.ERROR.0.TITLE'), 'error');
          break;
      }
    });
  }

  /**
   * Send prompt
   *
   * @param {GPT} gpt
   * @param promptId
   * @param prompt
   * @param conversations
   * @param systemPrompt
   * @param mode
   * @param userDocumentIds
   * @param externalSystems
   * @param model
   */
  public sendPrompt(gpt: GPT, promptId: string, prompt: string, conversations: GPTMessage[], systemPrompt?: string, mode?: string, userDocumentIds?: string[], externalSystems?: TreeNode[], model?: TreeNode) {
    /* Convert it to messages */
    const messages = this.convertGPTMessagesToPrivateGPTMessage(conversations);

    if (systemPrompt !== undefined) {
      messages.unshift({ role: 'system', content: systemPrompt } as PrivateGPTCompletionRequestMessage);
    }

    /* Get node ids and node fields if they are there */
    let nodeIds: string[];
    let nodeFields: string[];
    const count = conversations.length;
    for (let i = 0; i < count; i++) {
      const conversation = conversations[i];

      /* Node ids */
      if (conversation.nodeIds !== undefined) {
        if (nodeIds === undefined) {
          nodeIds = conversation.nodeIds;
        } else {
          nodeIds = nodeIds.concat(conversation.nodeIds);
        }
      }

      /* Node fields */
      if (conversation.nodeFields !== undefined) {
        if (nodeFields === undefined) {
          nodeFields = conversation.nodeFields;
        } else {
          nodeFields = nodeFields.concat(conversation.nodeFields);
        }
      }
    }

    /* Set up the prompt */
    const request = {
      type: 'privategpt',
      privateGPTCompletionRequest: {
        type: 'chat',
        url: model !== undefined ? model.documentUri : gpt.url,
        messages,
        use_context: mode === 'Query Docs',
        include_sources: mode === 'Query Docs',
        nodeIds,
        nodeFields
      } as PrivateGPTCompletionRequest
    } as GPTRequest;

    if (userDocumentIds !== undefined && request.privateGPTCompletionRequest.use_context) {
      request.privateGPTCompletionRequest.context_filter = {
        docs_ids: userDocumentIds
      };
    }

    if (externalSystems !== undefined && request.privateGPTCompletionRequest.use_context) {
      request.privateGPTCompletionRequest.externalSystems = externalSystems.map(es => es.id);
    }

    /* Send the prompt */
    this.coreService.gpt('completion', request).filter(response => response.choices !== undefined).take(1).subscribe((response) => {
      /* Send the event via emitter */
      gpt.events.next({ gpt, message: this.convertToGPTMessage(promptId, prompt, response), status: this.convertToGPTStatus(response) } as GPTResponse);
    });
  }

  /**
   * Get ingest documents
   *
   * @param {GPT} gpt
   * @param callback
   */
  public getIngestDocuments(gpt: GPT, callback: Function) {
    /* Set up the request */
    const request = {
      type: 'privategpt',
      privateGPTIngestedRequest: {
        url: gpt.url
      } as PrivateGPTIngestedRequest
    } as GPTRequest;

    this.coreService.gpt('ingested', request).take(1).subscribe((response) => {
      callback(this.mergeToDocuments(response));
    });
  }

  /**
   * Upload files
   *
   * @param {GPT} gpt
   * @param {FileList} files
   * @param callback
   */
  public ingestFile(gpt: GPT, files: FileList, callback: Function) {
    /* The file */
    const file = files[0];

    /* Form data */
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('url', gpt.url);
    formData.append('type', 'privategpt');

    /* Upload */
    this.coreService.gpt(AppGlobal.go + '/ai/v1/gpt/ingest/file', formData, {
      stringify: false
    }).take(1).subscribe((response) => {
      callback(this.mergeToDocuments(response));
    });
  }

  /**
   * Delete an ingested file
   *
   * @param {GPT} gpt
   * @param {GPTIngested} ingestFile
   * @param {Function} callback
   */
  public ingestDelete(gpt: GPT, ingestFile: GPTIngested, callback: Function) {
    /* Set up the request */
    const request = {
      type: 'privategpt',
      privateGPTIngestDelete: {
        url: gpt.url,
        doc_ids: ingestFile.pages.map(page => page.id)
      } as PrivateGPTIngestDeleteRequest
    } as GPTRequest;

    /* Delete */
    this.coreService.gpt('ingest/delete', request).take(1).subscribe((response) => {
      callback();
    });
  }

  /**
   * Close the connection
   *
   */
  public closeConnection() {
    if (this.eventSource !== undefined) {
      this.eventSource.close();
    }
  }

  /**
   * Convert a private gpt message to general GPT message
   *
   * @param {GPTMessage[]} gptMessages
   * @returns {any[]}
   * @private
   */
  private convertGPTMessagesToPrivateGPTMessage(gptMessages: GPTMessage[]) {
    /* Convert conversations to chat */
    const messages = [];

    /* Loop over messages */
    const count = gptMessages.length;
    for (let i = 0; i < count; i++) {
      const gptMessage = gptMessages[i];
      messages.push({ role: 'user', content: gptMessage.prompt } as PrivateGPTCompletionRequestMessage);
      if (gptMessage.response !== undefined && gptMessage.response !== '') {
        messages.push({ role: 'system', content: gptMessage.originalResponse } as PrivateGPTCompletionRequestMessage);
      }
    }

    /* Return messages */
    return messages;
  }

  /**
   * Convert the data object to GPT message
   *
   * @param {string} promptId
   * @param prompt
   * @param response
   * @returns {GPTMessage}
   * @private
   */
  private convertToGPTMessage(promptId: string, prompt: string, response: PrivateGPTCompletionResponse): GPTMessage {
    /* Stop if it's not completion */
    if (response.choices === undefined) {
      return { uuid: promptId, prompt, message: '', status: GPTStatus.PROCESSING, sources: [] };
    }

    /* The message */
    const message: GPTMessage = { uuid: promptId, prompt, message: '', status: this.convertToGPTStatus(response), sources: response.choices[0].sources };

    /* Add choices */
    message.response = response.choices[0].message.content;

    /* Return message */
    return message;
  }

  /**
   * Convert event data to GPT Status
   *
   * @returns {string}
   * @private
   * @param response
   */
  private convertToGPTStatus(response: PrivateGPTCompletionResponse): string {
    switch (response.choices[0].finish_reason) {
      case 'stop':
        return GPTStatus.COMPLETED;
    }
    return '';
  }

  /**
   * Merge documents
   *
   * @param {PrivateGPTIngestedResponse} response
   * @returns {Array<GPTIngested>}
   * @private
   */
  private mergeToDocuments(response: PrivateGPTIngestedResponse) {
    let documents = Map<string, GPTIngested>();
    const count = response.data.length;
    for (let i = 0; i < count; i++) {
      const datum = response.data[i];
      const document: GPTIngested = documents.has(datum.doc_metadata.file_name) ? documents.get(datum.doc_metadata.file_name) : {
        type: datum.object,
        name: datum.doc_metadata.file_name,
        pages: []
      };
      document.pages.push({ id: datum.doc_id, page: datum.doc_metadata.page_label } as GPTIngestedPage);
      document.pages = document.pages.sort((a, b) => this.coreUtilities.sort(a.page, b.page));
      documents = documents.set(datum.doc_metadata.file_name, document);
    }
    return documents.toArray();
  }

  private loadBookStackPages() {}

}
