import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

import { ActivityService } from '../../api/activities';
import { BusinessareaService } from '../../api/businessareas';
import { GroupService } from '../../api/groups';
import { HumanResourceService } from '../../api/humanresources';
import { ImportSchemeService } from '../../api/importschemes';
import { InstanceService } from '../../api/instances';
import { ModelService } from '../../api/models';
import { NodeDataService } from '../../api/nodedata';
import { NodeStructureService } from '../../api/nodestructures';
import { RelationshipService } from '../../api/relationships';
import { SubsetService } from '../../api/subsets';
import { TemplateService } from '../../api/templates';
import { VersionService } from '../../api/versions';
import { UserService } from '../../api/user';
import { AuditService } from '../../api/audits';
import { GoService } from '../../api/node';
import { SubscriptionService } from '../../utilities/subscription';
import { isNullOrUndefined } from 'util';
import { HierarchyService } from '../../api/hierarchy';
import { MiningService } from '../../api/mining';
import { FormService } from '../../api/form';

@Component({
  selector: 'app-loading',
  styleUrls: ['loading.component.scss'],
  templateUrl: './loading.component.html'
})

export class LoadingComponent implements OnInit, OnDestroy {

  @Input() forced = false;
  @Input() opaque = false;

  public loading = true;
  public currentLoading = 'APP.LOADING.PREPARING';

  private subscriptionService = new SubscriptionService();

  public constructor(private translateService: TranslateService,
                     private activityService: ActivityService,
                     private businessAreaService: BusinessareaService,
                     private groupService: GroupService,
                     private humanResourceService: HumanResourceService,
                     private importSchemeService: ImportSchemeService,
                     private instanceService: InstanceService,
                     private modelService: ModelService,
                     private nodeDataService: NodeDataService,
                     private nodeStructureService: NodeStructureService,
                     private relationshipService: RelationshipService,
                     private subSetService: SubsetService,
                     private templateService: TemplateService,
                     private versionService: VersionService,
                     private userService: UserService,
                     private auditService: AuditService,
                     private hierarchyService: HierarchyService,
                     private goService: GoService,
                     private miningService: MiningService,
                     private formService: FormService) {
    if (isNullOrUndefined(this.translateService.defaultLang)) {
      this.translateService.setDefaultLang('en');
    }
  }

  public static getLoadingTextByPosition(position: number) {
    switch (position) {
      case 0:
        return 'APP.LOADING.ACTIVITIES';
      case 1:
        return 'APP.LOADING.BUSINESSAREAS';
      case 2:
        return 'APP.LOADING.GROUPS';
      case 3:
        return 'APP.LOADING.HUMANRESOURCES';
      case 4:
        return 'APP.LOADING.IMPORTSCHEMES';
      case 5:
        return 'APP.LOADING.INSTANCES';
      case 6:
        return 'APP.LOADING.MODELS';
      case 7:
        return 'APP.LOADING.NODEDATA';
      case 8:
        return 'APP.LOADING.NODESTRUCTURES';
      case 9:
        return 'APP.LOADING.RELATIONSHIPS';
      case 10:
        return 'APP.LOADING.SUBSETS';
      case 11:
        return 'APP.LOADING.TEMPLATES';
      case 12:
        return 'APP.LOADING.USERS';
      case 13:
        return 'APP.LOADING.VERSION';
      case 14:
        return 'APP.LOADING.AUDITS';
      case 15:
        return 'APP.LOADING.NODESTRUCTURES';
      case 16:
        return 'APP.LOADING.MINING';
      case 17:
        return 'APP.LOADING.IPC';
      case 18:
        return 'APP.LOADING.PREBUILT';
      // case 19:
      //   return 'APP.LOADING.FORM';
    }
  }

  public ngOnInit(): void {
    /* Check if forced */
    if (!this.forced) {
      /* Subscribe */
      this.subscriptionService.add('notification.service.busy', Observable.combineLatest(
        this.activityService.isBusy,
        this.businessAreaService.isBusy,
        this.groupService.isBusy,
        this.humanResourceService.isBusy,
        this.importSchemeService.isBusy,
        this.instanceService.isBusy,
        this.modelService.isBusy,
        this.nodeDataService.isBusy,
        this.nodeStructureService.isBusy,
        this.relationshipService.isBusy,
        this.subSetService.isBusy,
        this.templateService.isBusy,
        this.userService.isBusy,
        this.versionService.isBusy,
        this.auditService.isBusy,
        this.goService.isBusy,
        this.miningService.isBusy,
        this.goService.isBusyIPC,
        // this.workflowService.isBusy,
        // this.formService.isBusy
        // this.hierarchyService.isBusy,
      ).subscribe(data => {
        const dataList = List<number>(data);
        if (dataList.max() === 0) {
          this.currentLoading = 'APP.LOADING.PREPARING';
          this.loading = false;
        } else {
          this.loading = true;
          this.currentLoading = this.getLoadingText(dataList);
        }
      }));
    }
  }

  public ngOnDestroy(): void {
    this.subscriptionService.remove();
  }

  private getLoadingText(dataList: List<number>) {
    let currentLoading = '';
    dataList.forEach((count, index) => {
      if (count > 0) {
        currentLoading = LoadingComponent.getLoadingTextByPosition(index);
      }
    });
    return currentLoading;
  }

}
